@import 'variables.css';
@import 'fonts.css';

* {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* antd customized */

.ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
}

.ant-layout-content {
  padding-top: 27px;
  padding-left: 31px;
  padding-right: 31px;
}

.ant-select {
  width: 100%;
  min-width: 160px;
  max-width: 500px;
}

.ant-select.fullwidth {
  width: 100%;
}

.pncdropdown .ant-space-item {
  width: 100%;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--lightgrey) !important;
}

.ant-upload.ant-upload-drag {
  height: 32px !important;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0 !important;
}

/* TABS */

.ant-tabs-tab {
  font-size: 18px !important;
  color: var(--text-secondary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 400;
}

.ant-tabs-ink-bar {
  height: 3px !important;
  border-radius: 6px;
}

/* TAGS */

.ant-tag {
  border: 0px !important;
}

.ant-tag-error {
  color: var(--error-text) !important;
  background: var(--error) !important;
}

.ant-tag-success {
  color: var(--success-text) !important;
  background: var(--success) !important;
}

.tabs-container > div.ant-tabs-nav {
  background: white;
  padding: 35px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.tabs-container .ant-tabs-nav::before {
  bottom: 0;
  margin: 0 35px;
}

/* TABLE */

.ant-table-thead th.ant-table-cell {
  font-weight: 300;
}

.compare-versions-table div.ant-table-container {
  height: 350px !important;
}

.compare-versions-table .ant-table-placeholder {
  height: calc(350px - 35.86px);
}

.compare-versions-table .ant-table.ant-table-small {
  font-size: 12px;
}

.compare-versions-table .ant-table-header > table {
  table-layout: auto !important;
}

/* CHECKBOX */
.missing-field .ant-checkbox {
  border: 1px solid red;
  border-radius: 2px;
}

/* SELECT */
.select-compare-version {
  max-width: none;
  min-width: none;
  width: 100%;
}

.space-compare-version > .ant-space-item {
  flex-grow: 1;
}

/* antd customized */

.login-wrapper {
  display: flex;
  height: 100vh;
}

.login-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-section.logo {
  background-color: var(--ancient);
}

.login-form-wrapper {
  width: 100%;
  max-width: 466px;
  padding: 30px;
}

.ant-btn.login {
  width: 100%;
  border-radius: 3px;
}

.input.login {
  height: 50px;
  border-radius: 6px;
}

a.login {
  float: right;
}

.login-divider {
  color: var(--text-secondary) !important;
  font-size: 14px !important;
}

.text-center {
  text-align: center;
}

.text-secondary {
  color: var(--text-secondary);
}

.underline {
  text-decoration: underline;
}

.login-logo {
  max-width: 50%;
}

.layout.main {
  min-height: 100vh;
}

.layout.inner {
  margin-left: 70px;
}

.sider-logo {
  width: 40px;
  height: 40px;
}

.sider-logo.container {
  padding: 15px;
  margin-bottom: 50px;
}

.page-title.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-title {
  font-size: 27px;
  font-weight: bold;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header.profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header.icon {
  font-size: 20px;
}

.header.setting {
  margin-left: 5px;
  margin-right: 10px;
}

.header.dropdown {
  display: inline-flex;
  align-items: center;
}

.header.username {
  font-size: 16px;
}

.header.back {
  font-size: 16px;
  font-weight: bold;
}

.header.icon.dropdown {
  font-size: 13px;
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 70px;
}

.menu-item.container {
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 5px !important;
  padding-left: 5px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--deselected);
}

.menu-item.container.selected {
  padding-left: 0px !important;
  border-left: 5px solid var(--ancient);
  color: var(--ancient);
  transition: color 0.3s;
}

.menu-item.icon {
  font-size: 20px;
}

.menu-item.label {
  font-size: 12px;
  width: 100%;
  margin-left: 0px !important;
  word-wrap: break-word;
  white-space: normal;
  line-height: 13px;
}

.filter.tag {
  border-radius: 15px;
  color: var(--text-primary);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  background-color: rgb(241, 249, 255);
  height: 30px;
  display: flex;
  align-items: center;
}

.filter.tag.icon {
  font-size: 12px;
  color: #4392f1;
}

.projects-expandable-row {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  margin-left: 200px;
  margin-right: 50px;
}

.projects-expandable-row > * {
  justify-self: end;
}

.projects-expandable-row > *:first-child {
  justify-self: start;
}

.legend {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
}

.legend-tags {
  height: 9px !important;
  width: 17px !important;
}

.portlet {
  padding: 35px;
  background-color: var(--primary);
  border-radius: 3px;
  height: auto;
}

.projects-list-section {
  /* min-height: 800px; */
  margin-bottom: 35px;
}

.projects-list-condition-tag {
  padding: 4px 8px !important;
  border-radius: 1px !important;
}

.projects-filter-section {
  padding-top: 12px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkpoint-section {
  height: 265px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.checkpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.checkpoint-name {
  margin-bottom: 0;
  transform: rotate(-45deg);
}

.checkpoint-date {
  margin-bottom: 0;
  margin-top: 1.2rem;
  color: var(--deselected);
  font-size: 13px;

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.diamond {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
}

.completed-checkpoint {
  background-color: var(--ancient);
  color: white;
  font-weight: bolder;
}

.parent-old {
  color: #fff;
  border: 4px solid var(--ancient);
  transform: rotate(45deg);
  height: 73px;
  width: 73px;
  background: white;
}

.selected-checkpoint {
  background-color: var(--lightgrey);
  color: var(--ancient);
  font-weight: bold;
  border: 2.2px solid var(--ancient);
}

.parent-old > .completed-checkpoint {
  transform: rotate(0deg);
  margin-top: 3px;
  margin-left: 3px;
}

.uncompleted-checkpoint {
  background-color: var(--lightgrey);
  color: var(--ancient);
}

.checkpoint-line {
  border: 0;
}

.dashed-line {
  border-top: 1.5px dashed var(--ancient);
}

.solid-line {
  border-top: 1.5px solid var(--ancient);
}

.checkpoint-separator {
  flex-grow: 1;
  position: relative;
}

.marker {
  display: flex;
  justify-content: center;

  width: 20px;
  height: 20px;
  background-color: var(--ancient);
  border-radius: 50%;

  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.markerCenter {
  left: calc(100% + 36px);
  background-color: transparent;
}

.marker-vertical-line {
  height: 113px;
  border-left: 1.5px solid var(--ancient);
}

.action-container {
  position: relative;
  margin-top: 35px;
}

.project-details-section {
  margin-bottom: 35px;
}

.project-details-section p {
  margin-bottom: 0 !important;
}

.project-overview-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 200px;
  margin: auto;
}

.model-overview-actions {
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.model-overview-actions svg {
  width: 30px;
  height: 30px;
}

#action-container {
  position: absolute;
}

.max-width {
  max-width: 100%;
}

.ant-typography.title {
  color: var(--text-primary) !important;
}

.convert-model.arrow {
  font-size: 35px;
  color: #838383;
}

.convert-model.specification {
  color: var(--text-secondary);
  font-size: 13px;
}

/* .select-model-version {
  min-width: 260px;
} */

.versions-steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.versions-step {
  display: flex;
  align-items: center;
  position: relative;
}

.step-circle {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 25px;
  background-color: var(--ancient);
  color: var(--primary);
  border-radius: 50%;
  font-size: 0.8rem;
}

.step-description {
  width: 150px;
  text-align: center;
  color: black;
  font-size: 0.7rem;
  margin-top: 0.7rem;

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.steps-separator {
  border: none;
  border-top: 1.5px solid var(--ancient);
  flex-grow: 1;
}

.button-like-padding {
  padding: 4px 15px;
}

.customdropdownmenu .multidropdowntaglabel {
  margin-right: 5px;
}

/* .customdropdownmenu .multidropdowntaglabel:not(.ant-select-selection-overflow-item-suffix)::after {
  content: ', ';
} */

.multidropdownoptions.ant-select-item-option-selected .ant-select-item-option-state {
  display: none;
}

.multidropdownoptions.ant-select-item-option-selected,
.multidropdownoptions.ant-select-item-option-active {
  background-color: #e1f5fd;
}

.hideit {
  display: none;
}

.customdropdownmenu.ant-select-multiple .ant-select-selection-overflow-item {
  /* display: none; */
}

.customdropdownmenu.ant-select-multiple .ant-select-selection-overflow-item.multilevelparentnode {
  /* display: block; */
}

.compare-versions-table-left .ant-table-body,
.compare-versions-table-right .ant-table-body {
  scroll-behavior: smooth;
}

.workflow-step-dot {
  height: 15px;
  position: absolute;
  top: -4.5px;
  left: 50%;
}

.workflow-step-dot.not-current {
  border-left: 2px solid;
  margin-left: -2px;
}

.workflow-step-dot.current {
  background-color: var(--ancient);
  width: 15px;
  margin-left: -10px;
  border-radius: 100px;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: var(--ancient) !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: var(--ancient) !important;
}

.rc-steps-dot .rc-steps-item-tail:after {
  height: 2px;
}

.rc-steps-item-title {
  font-weight: unset !important;
}

.tabs-container .projects-list-section::after {
  content: '';
  display: table;
  clear: both;
}

.chart-export-button-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
}

.greenBgColor,
.greenBgColorANC td:nth-child(4),
.greenBgColorModuleArea td:nth-child(1),
.greenBgColorModule td:nth-child(4),
.greenBgColorComponent td:nth-child(3),
.greenBgColorANCDescription td:nth-child(5),
.greenBgColorQuantity td:nth-child(7) {
  color: #225527;
  background-color: #d3e8d5;
}

.yellowBgColor,
.yellowBgColorANC td:nth-child(4),
.yellowBgColorModuleArea td:nth-child(1),
.yellowBgColorModule td:nth-child(4),
.yellowBgColorComponent td:nth-child(3),
.yellowBgColorANCDescription td:nth-child(5),
.yellowBgColorQuantity td:nth-child(8) {
  color: #ffc400;
  background-color: #fff1b8;
}

.redBgColor,
.redBgColorANC td:nth-child(4),
.redBgColorModuleArea td:nth-child(1),
.redBgColorModule td:nth-child(4),
.redBgColorComponent td:nth-child(3),
.redBgColorANCDescription td:nth-child(5),
.redBgColorQuantity td:nth-child(7) {
  color: #862d2f;
  background-color: #ffc4c4;
}

.infoModal .ant-modal-body {
  overflow: auto;
}

.informationPageTable tr th:first-child,
.informationPageTable tr td:first-child {
  width: 180px;
}

.informationPageTable1 tr th:first-child,
.informationPageTable1 tr td:first-child {
  width: 50%;
}

.valigntop {
  vertical-align: top;
}

.informationPageTable img {
  width: 100%;
}

.informationPageTable3 tr:first-child td,
.informationPageTable4 tr:first-child td {
  font-weight: bold;
  font-size: 12px;
}

.informationPageTable3 tr td,
.informationPageTable4 tr td {
  font-size: 12px;
}

.informationPageTable3 tr:nth-child(odd),
.informationPageTable4 tr:nth-child(odd),
.informationPageTable tr:nth-child(even) {
  background-color: #ededed;
}

.informationPageTable3 tr,
.informationPageTable4 tr,
.informationPageTable tr {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.informationPageTable3 tr td,
.informationPageTable3 tr th,
.informationPageTable4 tr td,
.informationPageTable4 tr th,
.informationPageTable tr td,
.informationPageTable tr th {
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.informationPageTable tr td > ul,
.informationPageTable tr th > ul {
  padding-left: 0px;
}

.informationPageTable tr td > ul > li,
.informationPageTable tr th > ul > li,
.informationPageTable3 tr td > ul > li,
.informationPageTable3 tr th > ul > li,
.informationPageTable4 tr td > ul > li,
.informationPageTable4 tr th > ul > li {
  list-style-position: inside;
  margin-bottom: 5px;
}

.informationPageTable3 tr td:nth-child(4) {
  width: 5%;
}

.informationPageTable3 tr td:nth-child(2) {
  width: 17%;
}

.informationBox .mainInfoOrderList > li {
  margin-bottom: 20px;
}

#Definitions ol > li {
  margin-bottom: 20px;
}

.informationPageTable1 tr {
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.informationPageTable1 tr td {
  padding-left: 10px;
  padding-right: 10px;
}

.informationPageTable1 tr:nth-child(2) {
  background-color: #ededed;
}

.informationPageTable4 tr td:first-child {
  font-weight: bold;
}

.translatePosition {
  writing-mode: vertical-lr;
  transform: translatex(10px);
}

.recharts-layer tspan {
  font-size: 12px;
}

.recharts-label-normal tspan {
  font-weight: normal;
}

.recharts-label tspan.boldfont {
  font-weight: bold;
}

.customtooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 10px #fff;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.informationBox {
  /* max-height: 500px;
  height: 95%; */
  overflow: auto;
  padding: 1rem;
  background-color: #f0f0f0;
  box-shadow: 0px 1px 4px 1px #e0d2d2;
}

.informationBox a {
  color: #345d9e;
}

/* .infomationIndex {
  list-style: none;
}

.infomationIndex>li {
  counter-increment: infomationIndex-list-style;
} */

/* .infomationIndex>li::before {
  color: #345D9E;
  content: counter(infomationIndex-list-style) ". ";
  margin-left: -1rem;
  display: inline-block;
  width: 1rem;
} */

/* .infomationIndex>li ol {
  list-style: none;
} */

.infomationIndex li::marker {
  color: #345d9e;
}

.InformationBoxDetails li {
  color: #000;
}

.InformationBoxDetails .ant-typography {
  color: #000;
}

.InformationBoxDetails > ol > li::marker {
  color: #000;
  font-weight: bold;
}

.yearly-volume-info-container {
  background-color: #e0e7ea;
  height: 100% !important;
  padding: 25px;
}

.yearly-volume-info-container p {
  margin: 0;
  color: var(--deselected);
}

.yearly-volume-info-container .model-info {
  margin-bottom: 2rem;
}

.cost-implication-table-legend-container {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 1rem;
}

.cost-implication-table-legend {
  height: 5px;
  width: 250px;
  margin: 0 1rem;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(147, 114, 89, 1) 0%,
    rgba(122, 138, 156, 1) 50%,
    rgba(120, 115, 134, 1) 100%
  );
}

.cost-implication-table-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin-left: auto;
  margin-bottom: 1rem;
}

.cost-implication-table-switch-container > p {
  margin: 0;
}

.hiderow {
  display: none;
}

.hideExpandalbeIcon td:first-child button {
  visibility: hidden;
}

.hideExpandalbeIcon td {
  font-weight: bold;
}

.hideBorder {
  border: none !important;
}

.headerCellWidth {
  /* width: 220px; */
}

.lightPorcelain {
  background-color: #f4f6f7;
}

.catskillWhite {
  background-color: #dde8ee;
}

.exportButton {
  width: auto;
  flex-direction: row;
  display: flex !important;
  align-items: center;
  margin-right: 5px;
}

.exportButton span {
  margin-right: 5px;
}

.headermargin {
  margin-top: -2px;
}

.inlineButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.inlineButtons button {
  margin-left: 20px;
}

.ancList-footer-action-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.verticleDropDown .ant-space {
  display: flex;
  flex-direction: column;
}

.verticleDropDown .ant-space .ant-space-item,
.verticleDropDown .ant-space .ant-space-item .ant-select {
  width: 100%;
}

.full-width {
  width: 100% !important;
}

.chartHeading {
  padding: 0.5rem;
  background-color: #dee2e7;
  margin-bottom: 1rem;
  font-weight: bold;
}

.recharts-tooltip-wrapper {
  z-index: 99999;
}

.customForm .ant-select {
  min-width: auto;
}

.hoverColor:hover,
.hoverColor:hover > td,
.hoverColor:hover > td a {
  background-color: #f0f0f0 !important;
  color: #000 !important;
}

.alignRight .ant-input-number-input,
.alignRight {
  text-align: right;
}

.alignRight .ant-input-number-input:focus,
.alignRight:focus {
  padding-right: 25px;
}

.numberHoverOriginal {
  display: none;
}

.numberHoverHandler:hover .numberHoverOriginal {
  display: none;
}

.numberHoverHandler:hover .numberHoverFormatted {
  display: block;
}

.ant-table-row-expand-icon {
  color: #000 !important;
}

.costMaturityOrderedList {
  margin-bottom: 0px;
}

.costMaturityOrderedList li:first-child {
  display: flex;
  align-items: center;
}

.costMaturityOrderedList li:first-child span {
  width: 10px;
  height: 10px;
  margin-right: 2px;
}

.costMaturityOrderedList li {
  text-align: center;
}

.transparentColor {
  background-color: transparent !important;
}

.maxWidthChart {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.maxWidthFix {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.hoverColor .customColor {
  background-color: rgb(244, 246, 247);
}
