:root {
  --primary: #ffffff;
  /* portlet background-color */
  --secondary: #f4f6f7;
  /* background-color; */
  --ancient: #061b45;
  /* blu electrolux */
  --lightgrey: #e0e7ea;

  --text-primary: #091f4d;
  --text-secondary: rgba(0, 0, 0, 0.45);
  --text-ancient: #ffffff;

  --item-selected: #f1f9ff;
  /* filters */
  --deselected: #767676;

  --primary-font: ElectroluxSans, sans-serif;

  --error: #ffc4c4;
  --error-text: #862d2f;

  --success: #d3e8d5;
  --success-text: #225527;
}
