@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Semibold.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Light.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Thin.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Oblique_Regular.otf') format('truetype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Oblique_Bold.otf') format('truetype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Oblique_Semibold.otf') format('truetype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Oblique_Light.otf') format('truetype');
  font-weight: 100;
  font-style: oblique;
}

@font-face {
  font-family: 'ElectroluxSans';
  src: url('../fonts/ElectroluxSans_Oblique_Thin.otf') format('truetype');
  font-weight: 200;
  font-style: oblique;
}
